import { IBurracoSession } from "../../../config/games/burraco/session";
import { IBurracoStore } from "./burraco";

const STORAGE_KEY = 'cards:games:burraco:sessions';

export class BurracoLocalStorageStore implements IBurracoStore {
    private sessions: IBurracoSession[] = [];

    constructor() {
        const data = window.localStorage.getItem(STORAGE_KEY);
        if (data !== null) {
            this.sessions = JSON.parse(data);
            this.parseStorage();
        }
    }

    create(session: IBurracoSession): void {
        this.sessions.push(session);
        this.parseStorage();
        this.refreshStorage();
    }

    getAll(): IBurracoSession[] {
        return this.sessions;
    }

    getOne(id: number): IBurracoSession | null {
        return this.sessions[id-1] || null;
    }

    private refreshStorage() {
        window.localStorage.setItem(STORAGE_KEY, JSON.stringify(this.sessions));
    }

    private parseStorage() {
        this.sessions = this.sessions.map(s => {
            return {
                ...s,
                date: new Date(s.date),
            };
        })
    }
}
