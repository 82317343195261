import React, { useEffect, useState } from 'react';
import { IPlayer } from '../../../../config/player';
import { PlayerForm } from './PlayerForm';
import { Select } from '../../../Components/Form/Select';
import { Button } from '../../../Components/Form/Button';
import { Player } from './Player';
import { Grid } from '@mui/material';

interface IPlayersProps {
  onChange(players: IPlayer[]): void;
}

export const Players = ({onChange}: IPlayersProps) => {
  const [edit, setEdit] = useState<boolean>(true);
  const [count, setCount] = useState<number>(2);
  const [players, setPlayers] = useState<IPlayer[]>([]);

  const handleChangeCount = (value: number) => {
    setCount(value);
  };

  const handleChange = (index: number) => (player: IPlayer) => {
    const callback = players?.map((current, i) => {
      if (i === index) {
        return player;
      }

      return current;
    });
    setPlayers(callback);
  };

  const handleSubmit = () => {
    setEdit(false);
    onChange(players);
  };

  useEffect(() => {
    [...Array(count)].map((_, i) => {
      if (!players[i]) {
        players.push({} as any);
      }
    });
  }, [count]);

  if (!edit) {
    return null;

    return (
      <Grid container spacing={2} sx={{marginBottom: 2}}>
        {players.map(player => <Player key={player.name} player={player} />)}
      </Grid>
    )
  }

  return (
    <Grid
      container
      spacing={2}
      flexDirection="row"
    >
      <Grid item style={{width: 150}}>
        <Select label={"Players"} range={[2, 6]} value={count} onChange={handleChangeCount} />
      </Grid>

      <Grid item>
        {[...Array(count)].map((_, i) => <PlayerForm key={i} id={i} player={players[i]} onChange={handleChange(i)} />)}
      </Grid>

      <Grid item>
        <Button onClick={handleSubmit} />
      </Grid>
    </Grid>
  )
};
