import { IPlayer } from "../../player";

export interface IBurracoOption {
    lowCards: number; // 5
    highCards: number; // 10
    aces: number; // 15
    pinellas: number; // 20 
    jokers: number; // 30

    lowCardsInHand: number; // -5
    highCardsInHand: number; // -10
    acesInHand: number; // -15
    pinellasInHand: number; // -20 
    jokersInHand: number; // -30

    royalBurraco: number; // 300 - without the use of jokers or flippers make a straight from the K (king) to the ace with the 2 card in its place.
    superBurraco: number; // 250 - 8 cards of the same type without the use of joker or pinella.
    cleanBurraco: number; // 200 - 7 cards of the same type or 7 cards of the same suit in ascending or descending order without the use of joker or pinella.
    semiCleanBurraco: number; // 150 - using the joker or flipper, 8 cards of which 7 are in a row without the ladder sequence being interrupted by the joker or flipper.
    dirtyBurraco: number; // 100 - 7 cards with use of joker or pinella.
    
    noCockpit: boolean; // -100
    winner: boolean; // 100
};

export interface IBurracoScore {
    player: IPlayer;
    score: number;
    options: IBurracoOption;
}

export const DEFAULT_BURRACO_OPTIONS = {
    lowCards: 0,
    highCards: 0,
    aces: 0,
    pinellas: 0,
    jokers: 0,

    lowCardsInHand: 0,
    highCardsInHand: 0,
    acesInHand: 0,
    pinellasInHand: 0,
    jokersInHand: 0,

    royalBurraco: 0,
    superBurraco: 0,
    cleanBurraco: 0,
    semiCleanBurraco: 0,
    dirtyBurraco: 0,
    
    winner: false,
    noCockpit: false,
};

export enum Score {
    LOW_CARD = 5,
    HIGH_CARD = 10,
    ACE = 15,
    PINELLA = 20,
    JOKER = 30,

    ROYAL_BURRACO = 300,
    SUPER_BURRACO = 250,
    CLEAN_BURRACO = 200,
    SEMI_CLEAN_BURRACO = 150,
    DIRTY_BURRACO = 100,

    NO_COCKPIT = -100,
    WINNER = 100,
}

interface IOptionItem {
    key: keyof IBurracoOption;
    label: string;
    description: string;
    score: Score;
}

export const cardOptions = [
    {
        key: 'lowCards',
        label: 'Low cards',
        description: '3, 4, 5, 6, 7',
        score: Score.LOW_CARD,
    },
    {
        key: 'highCards',
        label: 'High cards',
        description: '8, 9, 10, J, Q, K',
        score: Score.HIGH_CARD,
    },
    {
        key: 'aces',
        label: 'Aces',
        description: 'Ace',
        score: Score.ACE,
    },
    {
        key: 'pinellas',
        label: 'Pinella',
        description: 'Cards number 2',
        score: Score.PINELLA,
    },
    {
        key: 'jokers',
        label: 'Jokers',
        description: '',
        score: Score.JOKER,
    },
] as IOptionItem[];

export const burracoOptions = [
    {
        key: 'dirtyBurraco',
        label: 'Dirty Burraco',
        description: '7 cards with use of joker or pinella.',
        score: Score.DIRTY_BURRACO,
    },
    {
        key: 'semiCleanBurraco',
        label: 'Semi-clean Burraco',
        description: 'using the joker or flipper, 8 cards of which 7 are in a row without the ladder sequence being interrupted by the joker or flipper.',
        score: Score.SEMI_CLEAN_BURRACO,
    },
    {
        key: 'cleanBurraco',
        label: 'Clean Burraco',
        description: '7 cards of the same type or 7 cards of the same suit in ascending or descending order without the use of joker or pinella.',
        score: Score.CLEAN_BURRACO,
    },
    {
        key: 'superBurraco',
        label: 'Super Burraco',
        description: '8 cards of the same type without the use of joker or pinella.',
        score: Score.SUPER_BURRACO,
    },
    {
        key: 'royalBurraco',
        label: 'Royal Burraco',
        description: 'without the use of jokers or flippers make a straight from the K (king) to the ace with the 2 card in its place.',
        score: Score.ROYAL_BURRACO,
    },
] as IOptionItem[];
