import React from 'react';
import { Button } from '../../../Components/Form/Button';
import { Box } from '@mui/material';

interface ISaveProps {
  onSubmit(): void;
}

export const Save = ({onSubmit}: ISaveProps) => {
  return (
    <Box sx={{marginTop: 2, marginLeft: 1}}>
      <Button label="Save session" onClick={onSubmit} />
    </Box>
  )
};
