import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

interface ISelectProps {
  label: string;
  options?: SelectOption[];
  range?: [number, number];
  value?: any;
  onChange(value: any): void;
}

interface SelectOption {
  label: string;
  value: any;
}

export const Select = ({label, value, range, options, onChange}: ISelectProps) => {
  const [data, setData] = useState(value);

  const handleChange = (event: any) => {
    setData(event.target.value);
  };

  useEffect(() => {
    onChange(data);
  }, [data]);

  return (
    <TextField
      select
      label={label}
      value={data}
      onChange={handleChange}
      fullWidth
    >
      {!!options && options.map((option, i) => (
        <MenuItem key={i} value={option.value}>
          {option.label}
        </MenuItem>
      ))}

      {!!range && range.length === 2 && [...Array(range[1] - range[0] + 1)].map((v, i) => (
        <MenuItem key={i} value={range[0]+i}>
          {range[0]+i}
        </MenuItem>
      ))}
    </TextField>
  )
};
