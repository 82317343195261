import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

interface IFreeTextProps {
  label: string;
  value?: any;
  type?: string;
  size?: string;
  onChange(value: string): void;
}

export const FreeText = ({label, type = "text", size = "medium", value, onChange}: IFreeTextProps) => {
  const [data, setData] = useState(value);

  const handleChange = (event: any) => {
    setData(event.target.value);
  };

  useEffect(() => {
    onChange(data);
  }, [data]);

  return (
    <TextField
      type={type}
      size={size as any}
      label={label}
      value={data}
      onChange={handleChange}
      fullWidth
    />
  )
};
