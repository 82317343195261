import React from 'react';
import { BurracoStoresContext, createBurracoStores } from '../../../contexts/burraco';
import { Outlet } from 'react-router-dom';

const burracoStore = createBurracoStores();

export const Burraco = () => {
  return (
    <BurracoStoresContext.Provider value={burracoStore}>
      <Outlet />
    </BurracoStoresContext.Provider>
  );
}
