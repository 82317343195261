import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { IBurracoScore } from '../../../../config/games/burraco/options';
import { IPlayer } from '../../../../config/player';
import { TableFooter } from '@mui/material';
import { grey } from '@mui/material/colors';

interface IScoresListProps {
  players: IPlayer[];
  games: IBurracoScore[][];
}

export const ScoreList = ({players, games}: IScoresListProps) => {
  games.forEach(game => {
    game.sort((a, b) => {
      if (a.player.id < b.player.id) return -1;
      if (a.player.id > b.player.id) return 1;
      return 0;
    });
  });

  const totals = (players.map(() => 0));
  
  return (
    <Paper sx={{ width: (players.length * 210) + 100, overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table" size="small">
          <TableHead>
            <TableRow>
              {players.map((player, i) => <TableCell key={i} sx={{fontWeight: 'bold'}} align="center">{player.name}</TableCell>)}
            </TableRow>
          </TableHead>
          <TableBody>
            {games.map((game, i) => (
              <TableRow hover key={i}>
                {game.map((game, i) => {
                  totals[i] += game.score;
                  return (
                    <TableCell key={i} align="center">
                      {game.score}
                      {game.options.winner && " 🎉"}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
            <TableRow>
              {totals.map((score, i) => (
                <TableCell key={i} align="center" sx={{padding: 0.1, backgroundColor: grey[300]}}></TableCell>
              ))}
            </TableRow>
            <TableRow hover>
              {totals.map((score, i) => (
                <TableCell key={i} align="center" sx={{fontWeight: 'bold'}}>
                  {score}
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
