import { IBurracoOption, Score } from "../../../config/games/burraco/options";

export const calculateScore = (o: IBurracoOption): number =>  {
    return (
        (o.lowCards * Score.LOW_CARD) +
        (o.highCards * Score.HIGH_CARD) +
        (o.aces * Score.ACE) +
        (o.pinellas * Score.PINELLA) +
        (o.jokers * Score.JOKER) +

        (!o.winner ? (
            (o.lowCardsInHand * Score.LOW_CARD * -1) +
            (o.highCardsInHand * Score.HIGH_CARD * -1) +
            (o.acesInHand * Score.ACE * -1) +
            (o.pinellasInHand * Score.PINELLA * -1) +
            (o.jokersInHand * Score.JOKER * -1)
        ) : 0) +

        (o.royalBurraco * Score.ROYAL_BURRACO) +
        (o.superBurraco * Score.SUPER_BURRACO) +
        (o.cleanBurraco * Score.CLEAN_BURRACO) +
        (o.semiCleanBurraco * Score.SEMI_CLEAN_BURRACO) +
        (o.dirtyBurraco * Score.DIRTY_BURRACO) +

        (o.noCockpit ? Score.NO_COCKPIT : 0) +
        (o.winner ? Score.WINNER : 0)
    );
};
