import React, { useEffect, useState } from 'react';
import { IPlayer } from '../../../../config/player';
import { FreeText } from '../../../Components/Form/FreeText';
import { Box } from '@mui/material';

interface IPlayerFormProps {
  id: number;
  player?: IPlayer;
  onChange(player: IPlayer): void;
}

export const PlayerForm = ({id, player, onChange}: IPlayerFormProps) => {
  const [values, setValues] = useState<IPlayer>(player || {
    id: id,
    name: '',
  });

  const handleChange = (prop: keyof IPlayer) => (value: string) => {
    setValues({ ...values, [prop]: value });
  };

  useEffect(() => {
    onChange(values);
  }, [values]);

  return (
    <Box sx={{marginBottom: 2}}>
      <FreeText label="Player name" value={values.name} onChange={handleChange('name')} />
    </Box>
  );
};
