import { IPlayer } from "../config/player";

export const playersToSelect = (players: IPlayer[]) => {
    return players.map((player) => {
        return {
          label: player.name,
          value: player,
        };
      })
};
