import React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import { Burraco } from './components/Games/Burraco/Burraco';
import { Sessions } from './components/Games/Burraco/Sessions/Sessions';
import { Session } from './components/Games/Burraco/Sessions/Session';
import { Games } from './components/Games/Games';

function App() {
  return (
    <BrowserRouter>
      <Routes>
          <Route>
              <Route path="games" element={<Games />}>
                  <Route path="burraco" element={<Burraco />}>
                      <Route index element={<Sessions />} />
                      <Route path=":id" element={<Session />} />
                  </Route>
              </Route>
          </Route>
      </Routes>
  </BrowserRouter>
  );
}

export default App;
