import React, { useContext } from 'react';
import { BurracoStoresContext } from '../contexts/burraco';

export const useBurracoStore = () => {
    const store = useContext(BurracoStoresContext);
    if (!store) {
        throw new Error('useBurracoStore must of type IBurracoStores');
    }

    return store;
};
