import React from 'react';
import {Button as MuiButton} from '@mui/material';

interface IButtonProps {
  label?: string;
  onClick(): void;
  size?: string;
}

export const Button = ({label = "Save", size="medium", onClick}: IButtonProps) => {
  return (
    <MuiButton variant="contained" size={size as any} onClick={onClick}>{label}</MuiButton>
  )
};
