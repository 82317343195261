import React, { useEffect, useState } from 'react';
import { Players } from '../Players/Players';
import { NewScore } from '../Score/NewScore';
import { IPlayer } from '../../../../config/player';
import { IBurracoScore } from '../../../../config/games/burraco/options';
import { Box } from '@mui/material';
import { Title } from '../../../Components/Title';
import { ScoreList } from './ScoresList';
import { Save } from './Save';
import { useBurracoStore } from '../../../../hooks/use_burraco_store';
import { useNavigate, useParams } from 'react-router-dom';
import { IBurracoSession } from '../../../../config/games/burraco/session';
import { extractPlayersFromSession } from '../../../../helpers/games/burraco/extractPlayersFromSession';

interface ISessionProps {
}

export const Session = ({}: ISessionProps) => {
  const { burracoStore } = useBurracoStore();
  const params = useParams();
  const navigate = useNavigate();

  const [session, setSession] = useState<IBurracoSession>();
  const [players, setPlayers] = useState<IPlayer[]>([]);
  const [games, setGames] = useState<IBurracoScore[][]>([]);

  const handlePlayersChange = (players: IPlayer[]) => {
    setPlayers(players);
  };

  const handleScoreChange = (game: IBurracoScore[]) => {
    setGames([
      ...games,
      game,
    ]);
  };

  const handleSubmit = () => {
    burracoStore.create({
      date: new Date(),
      games: games,
    });

    navigate(`/games/burraco`);
  };

  useEffect(() => {
    const data = burracoStore.getOne(parseInt(params.id!));
    if (!data) {
      return;
    }

    setPlayers(extractPlayersFromSession(data));
    setGames(data.games);
    setSession(data);
  }, [params.id]);

  return (
    <Box>
      {!session ?
        <Title text="New session" /> :
        <Title text={`Session #${params.id} (${session.date.toLocaleDateString()} ${session.date.toLocaleTimeString()})`} />
      }

      {!session && <Players onChange={handlePlayersChange} />}

      {!!players.length && <ScoreList players={players} games={games} />}

      {!session && (
        <Box display="flex">
          {!!players.length && <NewScore players={players} onChange={handleScoreChange} />}
          {games.length >= 1 && <Save onSubmit={handleSubmit} />}
        </Box>
      )}
    </Box>
  );
}
