import * as React from "react";
import { Box, Typography } from "@mui/material";

interface ITitleProps {
    text: string;
}

export const Title = ({text}: ITitleProps) => (
    <Box sx={{marginBottom: 2}}>
        <Typography variant="h4" sx={{fontWeight: 'bold'}}>{text}</Typography>
    </Box>
)
