import React, { useEffect, useState } from 'react';
import {Box, Grid, Typography} from '@mui/material';
import { IPlayer } from '../../../../config/player';
import { DEFAULT_BURRACO_OPTIONS, IBurracoOption, burracoOptions, cardOptions } from '../../../../config/games/burraco/options';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { Checkbox } from '../../../Components/Form/Checkbox';
import { FreeText } from '../../../Components/Form/FreeText';
import styled from '@emotion/styled';
import { Button } from '../../../Components/Form/Button';
import { calculateScore } from '../../../../helpers/games/burraco/calculateScore';

interface IFormProps {
  winner: IPlayer;
  players: IPlayer[];
  onSubmit(options: IBurracoOption[], winnerIndex: number): void;
}

const LineContainer = styled(Box)(() => ({
  marginBottom: 20,
  borderBottom: 'solid 1px #efefef',
}));

const PlayerLabel = styled(Box)(() => ({
  fontWeight: 'bold',
  fontSize: 28,
  textAlign: 'center',
}));

const PlayerScore = styled(Typography)(() => ({
  display: 'block',
  textAlign: 'center',
}));

export const Form = ({winner, players, onSubmit}: IFormProps) => {
  const data = players.map(() => DEFAULT_BURRACO_OPTIONS);
  data.push({ ...DEFAULT_BURRACO_OPTIONS, winner: true });

  const WINNER_INDEX = data.length - 1;

  const [values, setValues] = useState<IBurracoOption[]>(data);

  const handleCockpitChange = (index: number) => (value: boolean) => {
    const callback = values.map((current, i) => {
      if (i === index) {
        return { ...current, noCockpit: !value };
      }

      return current;
    });
    setValues(callback);
  };

  const handleChange = (index: number, prop: keyof IBurracoOption) => (value: any) => {
    const callback = values.map((current, i) => {
      if (i === index) {
        return { ...current, [prop]: value };
      }

      return current;
    });
    setValues(callback);
  };

  const handleSubmit = () => {
    onSubmit(values, WINNER_INDEX);
  };

  return (
    <Box sx={{marginTop: 2}}>
      <Grid
        container
        spacing={4}
        justifyContent={"space-between"}
        sx={{marginBottom: 2}}
      >
        <Grid xs={2} item />
        <Grid xs={3} item>
          <PlayerLabel>{winner.name} 🎉</PlayerLabel>
          <PlayerScore variant="caption">{calculateScore(values[WINNER_INDEX])}</PlayerScore>
        </Grid>
        {players.map((p, i) => (
          <Grid key={i} item xs={3}>
            <PlayerLabel>{p.name}</PlayerLabel>
            <PlayerScore variant="caption">{calculateScore(values[i])}</PlayerScore>
          </Grid>
        ))}
      </Grid>

      <LineContainer>
        <Grid
          container
          spacing={4}
          justifyContent={"space-between"}
        >
          <Grid xs={2} item>
            <Typography variant="button" component="div" sx={{fontWeight: 'bold'}}>Cockpit</Typography>
          </Grid>
          <Grid xs={3} item />
          {players.map((_, i) => (
            <Grid key={i} item xs={3} sx={{textAlign: 'center'}}>
              <Checkbox value={!values[i].noCockpit} onChange={handleCockpitChange(i)} />
            </Grid>
          ))}
        </Grid>
      </LineContainer>

      {cardOptions.map(o => {
        return (
          <LineContainer>
            <Grid
              container
              spacing={4}
              justifyContent={"space-between"}
              sx={{marginBottom: 2}}
            >
              <Grid xs={2} item>
                <Typography variant="button" component="div" sx={{fontWeight: 'bold'}}>{o.label}</Typography>
                <Typography variant="caption" sx={{lineHeight: 0}}>{o.description}</Typography>
              </Grid>

              <Grid item xs={3}>
                <FreeText
                  label={"Cards down"}
                  type="number"
                  onChange={handleChange(WINNER_INDEX, o.key)}
                  value={values[WINNER_INDEX][o.key]}
                />
              </Grid>

              {players.map((p, i) => {
                return (
                  <Grid key={i} item xs={3}>
                    <Box>
                      {!values[i].noCockpit ?
                        <>
                          <FreeText
                            label={"Cards down"}
                            type="number"
                            onChange={handleChange(i, o.key)}
                            value={values[i][o.key]}
                          />
                          <br /><br />
                          <FreeText
                            label={"Cards in your hand"}
                            type="number"
                            onChange={handleChange(i, `${o.key}InHand` as any)}
                            value={(values[i] as any)[`${o.key}InHand` as any]}
                          />
                        </> :
                        <>
                          <FreeText
                            label={"Cards in your hand"}
                            type="number"
                            onChange={handleChange(i, `${o.key}InHand` as any)}
                            value={(values[i] as any)[`${o.key}InHand` as any]}
                          />
                        </>
                      }
                    </Box>
                  </Grid>
                )
              })}
            </Grid>
          </LineContainer>
        )
      })}

      {burracoOptions.map(o => {
        return (
          <LineContainer>
            <Grid
              container
              spacing={4}
              justifyContent={"space-between"}
              sx={{marginBottom: 2}}
            >
              <Grid xs={2} item>
                <Typography variant="button" component="div" sx={{fontWeight: 'bold'}}>{o.label}</Typography>
                <Tooltip title={o.description}>
                  <IconButton size="small">
                    <InfoOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </Grid>

              <Grid item xs={3}>
                <FreeText
                  label={o.label}
                  type="number"
                  onChange={handleChange(WINNER_INDEX, o.key)}
                  value={values[WINNER_INDEX][o.key]}
                />
              </Grid>

              {players.map((p, i) => {
                if (values[i].noCockpit) {
                  return (<Grid key={i} item xs={3} />)
                }

                return (
                  <Grid key={i} item xs={3}>
                    <Box>
                      <FreeText
                        label={o.label}
                        type="number"
                        onChange={handleChange(i, o.key)}
                        value={values[i][o.key]}
                        />
                    </Box>
                  </Grid>
                )
              })}
            </Grid>
          </LineContainer>
        )
      })}

      <div>
        <Button onClick={handleSubmit} />
      </div>
    </Box>
  )
};
