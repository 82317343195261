import React, { useState } from 'react';
import { Button } from '../../../Components/Form/Button';
import { Box, Grid, Paper, Typography } from '@mui/material';
import { IBurracoSession } from '../../../../config/games/burraco/session';
import { useBurracoStore } from '../../../../hooks/use_burraco_store';
import { useNavigate } from 'react-router-dom';
import { Title } from '../../../Components/Title';

interface ISessionsProps {
}

export const Sessions = ({}: ISessionsProps) => {
  const { burracoStore } = useBurracoStore();
  const navigate = useNavigate();

  const [sessions, setSessions] = useState<IBurracoSession[]>(burracoStore.getAll());

  const handleNavigate = (id: number) => () => {
    navigate(`/games/burraco/${id}`);
  };

  const handleNew = () => {
    navigate(`/games/burraco/new`);
  };

  return (
    <Box>
      <Title text="Burraco sessions" />

      <Grid container spacing={2} direction="column" sx={{width: 400}}>
        <Grid item sx={{marginBottom: 1}}>
          <Button label="Add new session" onClick={handleNew} />
        </Grid>

        {sessions.map((session, i) => (
          <Grid item key={i}>
            <Paper elevation={2} sx={{padding: 2}} onClick={handleNavigate(i+1)}>
              <Typography variant="subtitle1" component="span">
                Session <strong>#{i+1}</strong>
              </Typography>
              <Typography variant="body2" component="span">
                {' '}({session.games.length} game{session.games.length !== 1 ? 's' : ''})
              </Typography>
              <Typography variant="caption" component="div">
                {session.date.toLocaleDateString()} at {session.date.toLocaleTimeString()}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
};
