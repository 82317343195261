import { Checkbox as MuiCheckbox } from '@mui/material';
import React, { useEffect, useState } from 'react';

interface IFreeTextProps {
  value: boolean;
  onChange(value: boolean): void;
}

export const Checkbox = ({value, onChange}: IFreeTextProps) => {
  const [data, setData] = useState<boolean>(value);

  const handleChange = (event: any) => {
    setData(event.target.checked);
  };

  useEffect(() => {
    onChange(data);
  }, [data]);

  return (
    <MuiCheckbox
      checked={data}
      onChange={handleChange}
    />
  )
};
