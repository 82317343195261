import React, { useState } from 'react';
import { IPlayer } from '../../../../config/player';
import { Button } from '../../../Components/Form/Button';
import { Box } from '@mui/material';
import { Select } from '../../../Components/Form/Select';
import { playersToSelect } from '../../../../helpers/playersToSelect';
import styled from '@emotion/styled';
import { FormModal } from '../../../Components/FormModal';
import { Form } from './Form';
import { IBurracoOption, IBurracoScore } from '../../../../config/games/burraco/options';
import { calculateScore } from '../../../../helpers/games/burraco/calculateScore';

const Container = styled(Box)(() => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  border: '2px solid #000',
}));

interface INewScoreProps {
    players: IPlayer[];
    onChange(scores: IBurracoScore[]): void;
}

export const NewScore = ({players, onChange}: INewScoreProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [winner, setWinner] = useState<IPlayer>();

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleWinnerChange = (player: IPlayer) => {
      setWinner(player);
  };

  const handleSubmit = (options: IBurracoOption[], winnerIndex: number) => {
    onChange(options.map((v, i) => ({
      player: (i === winnerIndex ? winner! : players.filter(p => p.name !== winner?.name)[i]),
      score: calculateScore(v),
      options: v,
    })));
    handleClose();
  };

  return (
    <>
      <FormModal
        open={open}
        title="New score"
        loading={false}
        onClose={handleClose}
      >
        <>
            <Select
                label="Select winner"
                options={playersToSelect(players)}
                onChange={handleWinnerChange}
            />

            {!!winner &&
              <>
                <Form
                  winner={winner}
                  players={players.filter(p => p.name !== winner?.name)}
                  onSubmit={handleSubmit}
                />
              </>
            }
          </>
      </FormModal>

      <Box sx={{marginTop: 2}}>
        <Button label="Add new score" onClick={handleOpen} />
      </Box>
    </>
  )
};
