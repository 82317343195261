import * as React from "react";
import { IBurracoStore } from "../stores/games/burraco/burraco";
import { BurracoLocalStorageStore } from "../stores/games/burraco/localStorage";

interface IBurracoStores {
    burracoStore: IBurracoStore,
}

export const createBurracoStores = (): IBurracoStores => {
    return {
        burracoStore: new BurracoLocalStorageStore(),
    }
};

export const BurracoStoresContext = React.createContext<IBurracoStores | null>(null);
